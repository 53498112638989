


























































import { Prop, Component, Vue } from 'vue-property-decorator'
import { EGroupAuth, GroupPermission, IMemberRole, Member } from '@/models'
import GroupService from '@/services/GroupService'
import GroupMemberService from '@/services/GroupMemberService'
import updateGroupUserRole, {
  getRoleMutilangs
} from '@/helpers/UpdateGroupUserRole'
import { MemberViewModel } from './MemberRole.vue'

/**
 * f2-305a
 * サークル権限付与・委譲
 */
@Component
export default class GroupAuth extends Vue {
  @Prop() selectedMembers!: MemberViewModel[]
  private newMembers!: MemberViewModel[]
  private groupRoles: RolesViewModel[] = []
  private groupRolesWithoutAdmin: RolesViewModel[] = []
  private eApply: any = EApply
  private eGroupAuth: any = EGroupAuth
  private infoMess: string = ''
  private confirmLeaveIgnore: boolean = true

  created() {
    this.mapGroupAuthAndMembersRoles()
  }

  async listMemberSelectedRole(auths: any[]): Promise<number[]> {
    const res = await GroupMemberService.listMembersRole(
      this.$route.params.groupId,
      this.selectedMembers.map(item => item.id)
    )
    if (res.status === 200) {
      //get roles
      let selectedMemberRolesArr: EGroupAuth[] = res.data.map((item: any) => {
        const member = new Member(item)
        return member.role
      })

      //flat map
      let selectedMemberRoles = selectedMemberRolesArr.flatMap(item => item)
      let groupAuths = auths.flatMap(item => item.id)
      groupAuths.shift()
      selectedMemberRoles.forEach((item: EGroupAuth) => {
        if (item === EGroupAuth.ADMIN) {
          // case admin check every checkbox for user
          selectedMemberRoles = [...selectedMemberRoles, ...groupAuths]
        }
      })
      return selectedMemberRoles
    }
    return []
  }

  mapGroupAuthAndMembersRoles() {
    const vm = this
    GroupService.getGroupAuth()
      .then(async res => {
        if (res.status === 200) {
          let selectedMemberRoles = await this.listMemberSelectedRole(res.data)
          vm.groupRoles = res.data.filter((itemPermissions: any) => itemPermissions?.name == "full.permissions").map((auth: any) => {
            let checkedRole = selectedMemberRoles.filter(item => {
              return item === auth.id
            })
            let apply = EApply.NORMAL
            if (checkedRole.length === 1) {
              apply = EApply.SINGLE
            } else if (checkedRole.length > 1) {
              apply = EApply.MULTI
            }
            return new RolesViewModel({
              id: auth.id,
              role: getRoleMutilangs(auth.name),
              name: auth.name,
              apply: apply
            })
          })
          vm.groupRolesWithoutAdmin = [...vm.groupRoles]
          vm.groupRolesWithoutAdmin.shift()
        }
      })
      .catch(err => {
        this.infoMess = String(this.$t('common.message.uncatch_error'))
        this.$bvModal.show('modal-error')
      })
  }

  changeApply(index: number) {
    this.confirmLeaveIgnore = false
    if (index === 0) {
      const adminChecked = this.groupRoles[0].checked
      this.groupRoles.forEach(item => {
        item.checked = adminChecked
        item.apply = EApply.NORMAL
      })
    } else {
      const isAllCheck = this.groupRolesWithoutAdmin.every(item => item.checked)
      this.groupRoles[0].checked = isAllCheck
      this.groupRoles[0].apply = EApply.NORMAL
      this.groupRoles[index].apply = EApply.NORMAL
    }
  }

  onSubmitGroupAuth() {
    const roleChecked = this.groupRoles.filter(item => item.checked)
    const newPermission =
      roleChecked.map(item => item.id).length === this.groupRoles.length
        ? [EGroupAuth.ADMIN]
        : roleChecked.map(item => item.id)
    const memberRoles: IMemberRole[] = this.selectedMembers.map(item => {
      return {
        user_id: item.id,
        permission_ids: newPermission
      }
    })
    this.$blockui.show()
    GroupMemberService.updateMembersRole({
      group_id: Number(this.$route.params.groupId),
      member_roles: memberRoles
    })
      .then(res => {
        if (res.status === 200) {
          this.newMembers = this.selectedMembers.map(item => {
            item.user_permissions = roleChecked
            return new MemberViewModel(item, item.index)
          })
          updateGroupUserRole(Number(this.$route.params.groupId))
          this.$bvModal.show('modal-success')
          this.infoMess = this.$t('common.message.registered') as string
        }
      })
      .catch(err => {
        if (
          err.response.status === 400 &&
          err.response.data.message === 'UPDATE_GROUP_HAS_NO_ADMIN'
        ) {
          this.infoMess = this.$t('common.message.set_admin') as string
        } else {
          this.infoMess = this.$t('common.message.register_fail') as string
        }
        this.$bvModal.show('modal-error')
      })
      .finally(() => this.$blockui.hide())
  }

  openConfirmModal() {
    if (this.confirmLeaveIgnore) {
      this.$emit('back')
    } else {
      this.$bvModal.show('modal-confirm')
    }
  }

  backToMemberRole() {
    if (!this.$store.state.userInfo.user.group_role.role_admin) {
      this.$router.push({ name: 'group-toppage' })
    } else {
      this.$emit('submit', this.newMembers ? this.newMembers : [])
    }
  }
}

class RolesViewModel extends GroupPermission {
  checked: boolean = false
  apply: EApply = EApply.NORMAL

  constructor(init?: Partial<RolesViewModel>) {
    super()
    Object.assign(this, init)
    if (this.apply !== EApply.NORMAL) this.checked = true
  }
}

enum EApply {
  NORMAL,
  SINGLE,
  MULTI
}
