


















































































































import { Component, Vue } from 'vue-property-decorator'
import ModalShowProfile from '@/components/Modal/ModalShowProfile.vue'
import GroupAuth from '@/views/Group/TopPage/MemberList/GroupAuth.vue'
import { EGroupAuth, Member, UserProfile } from '@/models'
import GroupMemberService from '@/services/GroupMemberService'
import PreviewText from '@/helpers/PreviewText'

/**
 * f2-305
 * サークル管理者の変更・権限委譲
 */
@Component({ components: { ModalShowProfile, GroupAuth } })
export default class MemberRole extends Vue {
  private memberList: MemberViewModel[] = []
  private memberCount: number = 0
  private selectedMembers: MemberViewModel[] = []
  private currentStep: EStep = EStep.STEP_1
  private eStep: any = EStep
  private page: number = 1
  private maxPage: number = 1
  private noAvatar = require('@/assets/images/avatar-default.png')
  private selectedUserId: number = -1
  private limit: number = this.$store.state.setting.setting.setting_display
    .number_item_lists
  private confirmLeave: any = () => {}

  created() {
    this.getAllMember()
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    const confirmIgnore = !!(this.$refs as any).groupAuth?.confirmLeaveIgnore
    //this case for modal search header
    if (document.querySelectorAll('#modal-search-header').length) {
      next()

      //normal case
    } else if (this.currentStep === EStep.STEP_2 && !confirmIgnore) {
      this.confirmLeave = next
      this.$bvModal.show('group-auth-confirm-leave')
    } else {
      next()
    }
  }

  /**
   * Call API get all member of group
   */
  getAllMember(loadMore?: boolean) {
    GroupMemberService.getAllMembersByGroupId(
      this.$route.params.groupId,
      this.page,
      this.limit
    )
      .then(res => {
        if (res.status === 200) {
          const data = res.data.data.map((item: any) => {
            return new MemberViewModel(item)
          })
          if (loadMore) {
            this.memberList = [...this.memberList, ...data]
          } else {
            this.memberList = data
          }
          this.maxPage = res.data.last_page
          this.memberCount = res.data.total
        }
      })
      .catch(err => {
        this.$bvModal.show('modal-error-member-role')
      })
  }

  /**
   * Call API for loadmore
   */
  loadMore() {
    ++this.page
    this.getAllMember(true)
  }

  handleAbleAdminBtn() {
    this.selectedMembers = this.memberList
      .map((item, index) => {
        return new MemberViewModel(item, index)
      })
      .filter(item => item.selected)
  }

  async showMemberProfile(member: MemberViewModel, index: number) {
    this.selectedUserId = member.user_profile ? member.user_profile.user_id : -1
    // this.memberList[index].selected = !member.selected
    // const sameGroupTag = await this.getUserProfileById()
    // if (sameGroupTag) {
    this.$bvModal.show('modal-show-profile')
    // }
  }

  /**
   * Check user is same group tag
   */
  // async getUserProfileById() {
  //   return UserService.getUserProfileById(this.selectedUserId)
  //     .then(res => {
  //       return res.status === 200 && res.data.is_same_group_tag
  //     })
  //     .catch(() => {
  //       return false
  //     })
  // }

  jumpToStep2() {
    this.$route.params.step = '2'
    this.currentStep = EStep.STEP_2
  }

  backToStep1() {
    this.$route.params.step = '1'
    this.currentStep = EStep.STEP_1
  }

  submitAndBackToStep1(newMembers: MemberViewModel[]) {
    this.$route.params.step = '1'
    newMembers.map(mem => {
      if (mem.index !== undefined) {
        this.memberList[mem.index] = new MemberViewModel(mem)
      }
    })
    this.selectedMembers = []
    this.memberList.map(item => (item.selected = false))
    this.currentStep = EStep.STEP_1
  }

  getFullName(profile?: UserProfile) {
    const name = [
      profile?.first_name,
      profile?.last_name,
      String(this.$t('groups.member_list.name_suffix'))
    ].join(' ')
    const preview = PreviewText.covertToPreviewText(name, 15)
    return {
      name: name,
      text: preview.text,
      isLong: preview.isLong
    }
  }
}

export enum EStep {
  STEP_1,
  STEP_2
}

export class MemberViewModel extends Member {
  selected: boolean = false
  index?: number = 0

  constructor(init?: MemberViewModel | Member, index?: number) {
    super()
    Object.assign(this, new Member(init))
    this.index = index
    this.isAdmin = this.role.includes(EGroupAuth.ADMIN)
  }
}
